<script>
/**
 * @name: 菜单组件
 * @author: itmobai
 * @date: 2023-02-17 18:24
 * @description：菜单组件
 * @update: 2023-02-17 18:24
 */

import {mapState} from "vuex";
import SidebarItem from "./SidebarItem";
import variables from "@/assets/styles/variables.scss";
import Logo from '../logo/index.vue'

export default {
  name: 'AppMenu',
  components: {SidebarItem,Logo},
  computed: {
    ...mapState({
      menuList: state => state.useMenuStore.menuList,
      addRouters: state => state.useMenuStore.menuList,
    }),
    activeMenu() {
      const route = this.$route;
      const {meta, path} = route;
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    variables() {
      return variables;
    },
  },
  created() {
    console.log(this.addRouters)
  }
};
</script>
<template>
  <div class="left-content">
    <Logo/>
    <div class="aside scrollbar">
      <el-menu
        :default-active="activeMenu"
        :background-color="variables.menuBackground "
        :text-color="variables.menuColor"
        :unique-opened="true"
        :active-text-color="variables.menuColorActive"
        :collapse-transition="false"
        mode="vertical"
        router
      >
        <sidebar-item
          v-for="(route, index) in addRouters"
          :key="route.path  + index"
          :item="route"
          :base-path="route.path"
        />
      </el-menu>
    </div>
  </div>
</template>
<style lang="scss">
.left-content {
  position: relative;
  height: 100vh !important;
  width: 100%;
}

.aside {
  text-align: center;
  height: calc(100vh - 112px);
  overflow: auto;
}
.el-menu{
  border: none;
}
.el-menu.vh100 {
  height: 100% !important;
  border: none !important;
  i {
    color: #FFFFFF !important;
  }
}

.el-submenu__title {
  text-align: left !important;
  span {
    padding-left:38px;
    box-sizing: border-box;
  }
}

.el-menu-item > span {
  padding-left: 70px;
  box-sizing: border-box;
}

.el-menu-item.submenu-title-noDropdown > span {
  padding-left: 26px;
}

.el-menu-item {
  height: 52px !important;
  line-height: 52px !important;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  width: auto;
  border-radius: 10px!important;
  margin: 0 12px!important;
  position: relative;
  text-align: left !important;
  i {
    color: #FFFFFF !important;
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }

  :hover,
  ::after,
  ::before {
    background-color: transparent;
    color: #FFFFFF !important;
  }

  &.is-active {
    background-color: #FFFFFF !important;

    :hover,
    ::after,
    ::before {
      background-color: transparent;
      color: #007CC2 !important;
    }
    .active-div{
      position: absolute;
      right: 0;
      top: 15px;
      width: 4px;
      height: 20px;
      background: #00CBFF;
    }
  }
}

.el-submenu {
  .el-submenu__title {
    border-radius: 10px!important;
    i {
      color: #FFFFFF !important;
      margin-bottom:3px;
    }
  }

  &.is-active {
    .el-submenu__title {
      i {
        color: #FFFFFF !important;
      }
    }
  }
}


</style>
